// Breakpoints
$ltablet: 1024px;
$tablet: 768px;
$stablet: 640px;
$phablet: 480px;

// Styling
$border-radius: 5px;
$box-shadow: 0 .8rem 1.6rem rgba(0, 0, 0, .15);
$block-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
$centered-box-shadow: 0 0 20px 8px rgba(129, 129, 129, 0.1);
$transition: all .2s ease;
$transition-slow: all .5s ease;
$transition-slowest: all .7s ease;
$bold: 600;
$bolder: 900;
$light: 300;
$font-mdi: normal normal normal 24px/1 "Material Design Icons";
$top-bar-menu-width: 220px;
$dropdown-avatar-size: 30px;

$sidebar-width: 250px;
$sidebar-collapsed-width: 60px;
$sidebar-logo-container-height: 60px;

// Colours
$white: #ffffff;
$black: #222;

$lightestgrey: #fbfbfb;
$lightergrey: #F0F0F0;
$lightgrey: #DFDFDF;
$grey: #ced4da;
$darkgrey: #999999;
$darkergrey: #666666;
$darkestgrey: #222222;
$bordergrey: #E4E7EB;

$slategrey: #89969F;

$lightred: #F5C6C9;
$red: #E74C3C;

$yellow: #F9C922;

$lighterorange: #FFF6D5;
$lightorange: #FFB100;
$orange: #F68C3D;
$darkorange: #FF9800;

$blue: #27AAE1;
$lightblue: lighten($blue, 30);
$lighterblue: lighten($blue, 40);
$darkblue: #007AD2;


$green: #28a745;

$orange-gradient: linear-gradient(to bottom, $lightorange, $darkorange);

$primary-color: $blue;
$primary-color-hover: #006AAC;
// $primary-color-hover: #FFC20E;
$primary-color-yellow: #FFC20E;
$secondary-color: $orange;
$secondary-color-hover: #cc6d26;
$color-error: #f8d7da;
$border-color: $grey !default;

// Colour Indicators
$indicators: (
    'blue': $blue,
    'orange': $orange,
    'red': $red,
    'green': $green,
    'slategrey': $slategrey,
    'darkgrey': $darkgrey,
);

