.step-progress-bar {
  &__container {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &__progressbar {
    counter-reset: step;
    padding: 0px;
    margin: 0px 0px 15px 0px ;
  }
  &__progressbar li { /* text and number */
    list-style-type: none;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: $blue;
    width: 12.8rem;
  }

  &__progressbar li:first-child {
    list-style-type: none;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: $lightgrey;
    padding: 0px;
  }

  &__progressbar li:before { /* circle */
    width: 30px;
    height: 30px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    border: 2px solid $lightgrey;
    display: block;
    text-align: center;
    margin: 0 42px 10px;
    border-radius: 50%;
    background-color: white;
  }
  &__progressbar li:after { /* lines */
    width: 80%;
    height: 4px;
    content: '';
    font-size: 8px;
    font-family: "Material Design Icons";
    position: absolute;
    background-color: $lightgrey;
    top: 16px;
    left: -50%;
    z-index: -1;
    text-align: right;
    vertical-align: baseline;
  }
  &__progressbar li:first-child:after {
    content: none;
    width: 0;
    padding: 0px;
    margin: 0px;
  }

  &__progressbar li.active {
    color: $blue;
  }
  &__progressbar li.active:before {
    border-color: $blue;
  }
  &__progressbar li.active + li:after {
    background-color: $lightgrey;
  }

  &__progressbar li.completed {
    color: $blue;
  }
  &__progressbar li.completed:before {
    border-color: $blue;
    background-color: $blue;
    color: $white;
    content: "\F12C";
    font-size: 18px;
    font-family: "Material Design Icons";
  }
  &__progressbar li.completed + li:after {
    background-color: $blue;
  }

  &__arrow:before {
    position: absolute;
    content: '\f35f';
    font-family: "Material Design Icons";
    font-size: 28px;
    color: $lightgrey;
    background-color: transparent;
    top: 1px;
    vertical-align: baseline;
    left: 25px;
  }

  &__arrow.active:before {
    color: $blue;
  }

  &__arrow.completed:before {
    color: $blue;
  }

  &__subtitle {
    font-size: 8px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: $darkestgrey;
  }

}
