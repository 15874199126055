@mixin colorIndicators {
  @each $name, $color in $indicators {
    &--#{$name} {
      background: $color;
    }
  }
}

@mixin colorTextIndicators {
  @each $name, $color in $indicators {
    &--#{$name} {
      color: $color;
    }
  }
}
