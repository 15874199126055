.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: $transition;
  opacity: 0;
  display: none;
  top: 0;
  left: 0;
  z-index: -1;

  &--visible {
    opacity: 1;
    display: flex;
    z-index: 1;
  }

  &--is-fading-in {
    display: flex;
  }

  &--is-fading-out {
    opacity: 0;
    z-index: 1;
  }

  &--overlay {
    background: rgba(255, 255, 255, .8);
  }

  &--solid-overlay {
    background: rgba(255, 255, 255, 1);
  }

  &__line {
    display: block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid transparent;
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &--outer {
      width: 150px;
      height: 150px;
      border-top-color: $blue;
      animation-duration: 2s;
      animation-direction: reverse;
    }

    &--middle {
      width: 130px;
      height: 130px;
      border-top-color: $orange;
      animation-duration: 1.6s;
    }

    &--inner {
      width: 110px;
      height: 110px;
      border-top-color: $yellow;
      animation-duration: 1.3s;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
    }

    &--small-outer {
      width: 80px;
      height: 80px;
      border-width: 2px;
    }

    &--small-middle {
      width: 65px;
      height: 65px;
      border-width: 2px;
    }

    &--small-inner {
      width: 50px;
      height: 50px;
      border-width: 2px;
    }

    &--tiny-outer {
      width: 30px;
      height: 30px;
      border-width: 2px;
    }

    &--tiny-middle {
      width: 25px;
      height: 25px;
      border-width: 2px;
    }

    &--tiny-inner {
      width: 15px;
      height: 15px;
      border-width: 2px;
    }
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
