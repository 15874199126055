.roles {
  &__table {
    .table__cell {
      text-align: center;

      > i.mdi-check {
        color: $green;
      }

      > i.mdi-close {
        color: $red;
      }
    }

    .tooltip {
      right: 5px;
      top: 10px;

      &__icon {
        color: white;
        background: transparent;

        &--active {
          color: #007AD2;
        }
      }
    }
  }
}
