.transaction-detail-view {
  &__amount-container {
    text-align: right;
    display: flex;
  }
  
  &__method {
    flex: 1;
    text-align: left;
    font-size: 16px;
    align-self: center;
  }

  &__amount {
    flex: 0 1;
    text-align: right;
    font-size: 20px;
    align-self: center;
    position: relative;
    padding-top: 15px;

    &:before {
      content: 'Amount';
      font-size: 12px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__otc-input{
    margin-bottom: 8px;
  }

  &__disabled-option{
    background-color: $cloud;
  }
  
  &__text-area{
    height: 150px;
  }

  &__tooltip-amount{
    display: flex;
    min-height: 17px;
    min-width: 58px;
    
    .tooltip {
      right: 0px;
      left: 5px;
      top: 0px;
      position: relative;
    }

    .tooltip__button {
      height: 15px;
    }
    .tooltip__icon--active, .tooltip__icon {
      background-color: transparent;
      color: inherit;
    }
  }

  &__instructions-container{
    display: flex;
  }
  
  &__instructions{
    font-size: medium;
    padding-left: 10px;
    font-weight: 600;
  }

  &__button{
    min-width: 140px;
  }

  &__custom-tooltip {
    display: flex;
    min-height: 24px;
    .tooltip {
      right: 0px;
      left: 0px;
      top: 0px;
      position: relative;
      color: $amber;
    }

    .tooltip__button {
      height: 15px;
    }
    .tooltip__icon--active, .tooltip__icon {
      background-color: transparent;
      color: inherit;
    }
  }
}