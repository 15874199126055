.report {
    display: flex;
    flex-direction:row;
    flex: 1;
    
    &__content-container {
      padding: 0;
    }

    &__custom-tooltip {
      display: flex;
      min-height: 17px;
      .tooltip {
        right: 0px;
        left: 15px;
        top: 0px;
        position: relative;
      }
  
      .tooltip__button {
        height: 15px;
      }
      .tooltip__icon--active, .tooltip__icon {
        background-color: transparent;
        color: inherit;
      }
    }

    &__submit{
      max-width: max-content;
    }

    &__date-picker{
      width: 406px;
    }
  }
  