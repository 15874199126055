@import 'assets/styles/base/design/variables';

.slide-view {
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  box-shadow: none;
  padding: 50px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: #fbfbfb;

  &-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 25px;
  }

  &-content {
    overflow: scroll;
    height: 100%;
  }

  .nav-tabs {
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 20px;

    .active {
      background: none;
      border: none;
      border-bottom: 2px solid $ice;
      font-weight: bold;
    }
  }

  .card {
    margin-top: 20px;

    .btn-link {
      &:hover,
      &:not(:hover) {
        text-decoration: none;
      }
    }
  }
}
