* {
  font-family: 'Open Sans', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.25;
}

body {
  color: $black;
  font-size: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-style: normal;

  span {
    padding-top: .2em !important;
    vertical-align: top !important;

    small {
      font-weight: 400;
      font-size: 10px;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }
}

h1 {
  font-weight: 300;
}

h2 {
  font-weight: 300;
  font-size: 2.65rem;
}

h3 {
  font-weight: 400;
}

h4 {
  font-weight: 400;
}

h5 {
  font-weight: 300;
}

h6 {
  font-weight: 400;
}

a {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.text-small {
  font-size: 0.8em;
}

.text-bold {
  font-weight: bold;
}
