.tabs {
  height: 100%;
  overflow: auto;

  &__tab-content {
    display: none;
    height: calc(100% - 40px);
    overflow: auto;
    padding: 20px 40px;
    position: relative;

    &--active {
      display: flex;
      flex-direction: column;
    }

    &--without-buttons {
      height: 100%;
      padding: 20px;
    }
  }

  &__tab-buttons {
    padding: 0 30px;
    height: 40px;
    display: flex;
    border-bottom: 1px solid $lightergrey;
  }

  &__tab-button {
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-right: 10px;
    height: 100%;
    transition: $transition;
    color: $darkgrey;
    box-sizing: border-box;
    font-weight: 600;

    &:hover {
      cursor: pointer;
      color: $darkergrey;
    }

    &--active {
      border-bottom: 2px solid $blue;
      color: $darkergrey;
    }
  }

}
