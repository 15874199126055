$detail-view-width: 500px;
$toolbar-height: 40px;

.detail-view {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 0;
  min-width: 0;
  background: $white;
  box-shadow: $block-shadow;
  border-radius: $border-radius;
  margin-left: 0;
  transition: $transition-slow;
  opacity: 0;
  transform: translateX(100px);
  overflow: hidden;
  min-height: 300px;

  &--visible {
    width: $detail-view-width;
    max-width: $detail-view-width;
    min-width: $detail-view-width;
    margin-left: 30px;
    opacity: 1;
    transform: translateX(0);
  }

  &__inner {
    transition: $transition;
    overflow-y: auto;
    &--transitioning {
      overflow: hidden;
    }
  }

  &__toolbar {
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: $toolbar-height;
    border-bottom: 1px solid $lightergrey;
    color: $darkgrey;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }

  &__tool-button {
    margin: 0;
    height: 40px;
    width: 40px;
    transition: $transition;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    &:hover {
      color: darken($darkgrey, 50%);
      cursor: pointer;
    }
  }

  &__edit-button {
    color: $blue;
    height: 40px;
    width: 40px;
    transition: $transition;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    &:hover {
      color: darken($blue, 20%);
      cursor: pointer;
    }

    &--disabled {
      color: $grey;
      pointer-events: none;
    }
  }

  &__content {
    transition: $transition;
    min-height: 260px;
    display: flex;
    flex-direction: column;
  }

  &__spinner {
    height: calc(100% - #{$toolbar-height});
    margin-top: $toolbar-height;
  }

  &__silent-loader {
    // Overriding some material-ui defaults. It's a sad day. We're possibly creating our own soon.
    height: 2px !important;
    position: absolute !important;
    background-color: lighten($blue, 40) !important;
    top: $toolbar-height;
    width: 100%;

    * {
      background-color: $blue !important;
    }
  }

  &__section-title {
    &--standalone {
      display: flex;
      font-weight: 600;
      padding: 20px 20px 10px 20px;
      margin: 0;
      color: darken($white, 25);
    }

    &--profile {
      display: flex;
      align-items: center;
      background: $lightergrey;
      font-weight: 600;
      padding: 12px;
      margin: 0;
    }
  }

  &__section-content {
    padding: 15px 20px;
  }

  &__header-button {
    display: flex;
    flex-direction: row-reverse;
    flex: 1;
    margin: 0;
    margin-left:5px;
  }

  &__item {
    padding: 15px 0;
    border-bottom: 1px solid $lightergrey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border: 0;
      padding-bottom: 0;
    }
  }

  &__name {
    font-weight: $bold;
  }

  &__value {
    text-align: right;
    font-weight: 600;
    padding-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__highlight {
    background: $lightergrey;
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    &-item {
      padding: 5px 0;
      margin-top: 5px;
    }
    &-name {
      font-weight: $bold;
    }

    &-value {
      color: $blue;
      display: flex;
      line-height: 25px;
    }
    &--loading {
      pointer-events: none;
    }
  }

  &__tab-container {
    flex: 1;
    overflow: hidden;
  }

  &__tab {
    display: none;
    height: calc(100% - 45px);
    overflow: auto;

    &--active {
      display: flex;
      flex-direction: column;
    }
  }

  &__tab-buttons {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 0 10px;
  }

  &__tab-button {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: small;
    padding: 0 10px;
    margin-right: 10px;
    height: 35px;
    border-bottom: 2px solid transparent;
    transition: $transition;
    color: $darkgrey;

    &:hover {
      cursor: pointer;
    }

    &--active {
      color: $darkergrey;
      border-bottom: 2px solid $blue;
    }
  }

  &__link{
    text-decoration: underline;
    cursor: pointer;
  }
}
