/* Colors */
$amber: #F68C3D;
$azure: #006AAC;
$gold: #FFC20E;
$ice: #27AAE1;
$charcoal: #333333;
$cloud: #C4C4C4;
$coconut: #FFFFFF;

/* Gradients */
$tequila-sunrise: linear-gradient(180deg, #FFC20E 0%, #F68C3D 100%);
$blue-hawaiian: linear-gradient(180deg, #27AAE1 0%, #006AAC 100%);
$bungalow: linear-gradient(180deg, #006AAC 0%, #003860 100%);
$iron: linear-gradient(180deg, #919191 0%, #333333 100%);
$steel: linear-gradient(180deg, #FFFFFF 0%, #C4C4C4 100%);
$sunset: linear-gradient(180deg, #003860 62.5%, #FFC20E 100%);
$pass: linear-gradient(180deg, #79D900 0%, #5CA500 100%);
$fail: linear-gradient(180deg, #E00303 0%, #A10000 100%);

/* Shadows */
$elevate: 6px 4px 18px rgba(0, 0, 0, 0.2);
$sink: inset 6px 4px 4px rgba(0, 0, 0, 0.2);
