/*Container*/
.search-container {
  margin: 20px 0;
  width: 100%;
  max-width: 300px;
}
.search-label {
  position: relative;
}
.search-container input {
  width: 100%;
  padding: 10px 10px 10px 40px;
  font-size: 12px;
  color: #444;
  box-sizing: border-box;
  outline: none;
  border: none;
  box-shadow: 0px 1px 4px rgba(208, 208, 208, 0.25);
}
.search-icon {
  position: absolute;
  top: 1px;
  font-size: 14px;
  color: #555;
  left: 14px;
}
