.table {
  min-width: 0;

  &__wrapper {
    border-radius: $border-radius;
    box-shadow: $block-shadow;
    overflow-y: auto;
  }

  &__table {
    width: 100%;
    overflow: hidden;
    border-collapse: collapse;
  }

  &__header {
    background: $white;
    color: $darkgrey;
    height: 45px;
    border-bottom: 1px solid $lightergrey;

    .mdi {
      cursor: pointer;
    }
  }

  &__row {
    height: 4.6rem;
    background: $lightestgrey;
    border-bottom: 0.1rem solid $lightergrey;
    transition: $transition;

    &:nth-child(even) {
      background: $white;
    }

    &--no-border {
      border-color: transparent;
    }

    &--hover-enabled {
      &:hover {
        cursor: pointer;
        background: $lightergrey;
      }
    }

    &--selected {
      &, &:nth-child(odd), &:nth-child(even), &:hover {
        cursor: pointer;
        font-weight: 600;
        color: $white;
        background-color: $blue;
      }
    }

    & .loader {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }
  }

  &__cell {
    text-align: left;
    padding: 10px 25px;

    &--header {
      font-weight: bold;
    }

    &--with-icon {
      padding-left: 45px;
      position: relative;
    }

    &--green {
      color: $green;
    }

    &--red {
      color: $red;
    }
  }

  &__cell-icon {
    font-size: 16px;
    position: absolute;
    left: 25px;
    top: 10px;

    &--download {
      left: unset;
      top: 1px;
      position: relative;
      margin-right: 5px;
    }
  }

  &__selected-icon {
    opacity: 0;
    width: 30px;
    transition: opacity .2s ease;
    padding-right: 10px;
    font-size: 16px;
    text-align: right;

    &--selected {
      opacity: 1;
    }
  }

  &--with-pagination {
    box-shadow: none;
  }

  &__empty {
    white-space: nowrap;
    padding: 10px;
  }

  &__cell-download {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__cell-download-button{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px 0 5px;
    height: 30px;
    border-radius: $border-radius;
    transition: $transition;
    color: $blue;

    &:hover {
      background: $lightergrey;
      cursor: pointer;
    }
  }

  &__cell-download-size {
    margin-left: 5px;
    font-size: 10px;
  }
}
.table-with-pagination {
  flex: 1 1;
  margin-bottom: 30px;
  min-width: 0;

  &__wrapper {
    display: flex;
    height: fit-content;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
    box-shadow: $block-shadow;
    border-radius: 0 0 $border-radius $border-radius;
  }
}

.pagination {
  background: $white;
  display: flex;
  align-items: center;
  border-radius: 0 0 $border-radius $border-radius;
  overflow: hidden;

  &__information {
    padding-left: 20px;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__limit-selector {
    display: flex;
    flex: 1;
    align-items: center;
    padding-right: 15px;
  }

  &__limit-selector-input {
    padding-right: 25px;
    margin-bottom: 0;
    min-width: 70px;
  }

  &__limit-selector-label {
    line-height: 45px;
    padding-right: 10px;
    flex: 1;
    text-align: right;
  }

  &__wrapper {
    display: flex;
    padding: 10px;
    flex: 1;
    justify-content: center;
  }

  &__item {
    line-height: 29px;
    background: $white;
    height: 100%;
    position: relative;
    text-align: center;
    color: $darkergrey;
    padding: 0 10px;

    &:hover {
      color: $blue;
      cursor: pointer;
      font-weight: 600;
    }

    &--active {
      color: $blue;
      font-weight: 600;
      border-bottom: 2px solid $blue;

      &:hover {
        color: $darkblue;
      }
    }

    &--disabled {
      color: #666666;
      opacity: 0.4;
      pointer-events: none;
    }

    &--with-icon {
      padding-top: 7px;
      line-height: initial;
    }
  }

  &__navigation {
    &--disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  &__icon {
    opacity: 0.8;
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    position: relative;
    color: $darkergrey;
    font-size: 16px;

    &--start:after {
      position: absolute;
      content: "\F141";
      left: 5px;
    }

    &--end:after {
      position: absolute;
      content: "\F142";
      right: 5px;
    }
  }
}
