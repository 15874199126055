.button-wrapper {
  display: flex;
  position: relative;
  margin: 15px 0;
  height: 40px;
}

.button {
  border-radius: $border-radius;
  min-width: 160px;
  border: none;
  padding: 0 20px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  user-select: none;
  text-align: center;
  transition: $transition;
  outline: none;
  text-transform: capitalize;

  &:hover {
    cursor: pointer;
  }

  &--primary {
    background: $primary-color;
    border-color: $primary-color;
    color: white;

    &:active, &:hover {
      background: #006aac;
      border-color: #006aac;
    }
  }

  &--secondary {
    background: $secondary-color;
    border-color: $secondary-color;
    color: white;

    &:active, &:hover {
      background: $secondary-color-hover;
      border-color: $secondary-color-hover;
    }
  }

  &--primary-disabled,
  &--secondary-disabled {
    background: $lightgrey;
    border-color: $lightgrey;
    color: $darkgrey;

    &:active, &:hover {
      background: $lightgrey;
      cursor: not-allowed;
      border-color: $lightgrey;
    }
  }

  &--tertiary {
    background: #e9e9e9;
    border-color: #e9e9e9;
    color: #666666;
    min-width: 0;
    height: 0;
    font-weight: bold;
    padding: 15px 10px;
    margin-left: 10px;

    &:active, &:hover {
      background: #e7f1fd;
      border-color: #27aae1;
      color: #27aae1;

      path {
        fill: #27aae1;
      }
    }

    &-disabled {
      pointer-events: none;
      opacity: .4;
    }
  }

  &--primary-disabled,
  &--secondary-disabled {
    background: $lightgrey;
    border-color: $lightgrey;
    color: $darkgrey;

    &:active, &:hover {
      background: $lightgrey;
      cursor: not-allowed;
      border-color: $lightgrey;
    }
  }

  &--text {
    color: $blue;
    background: none;

    &-disabled {
      pointer-events: none;
      opacity: .7;
    }
  }
  &--text--disabled {
    cursor: not-allowed;
    color: $darkgrey;
  }

  &--small {
    min-width: 0;
    padding: 0 24px;
    font-size: 13px;
    height: 34px;
  }

  &--tiny {
    min-width: 0;
    padding: 0 12px;
    font-size: 12px;
    height: 24px;
    align-self: center;
  }
}
