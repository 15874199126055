.tooltip {
  align-self: center;
  position: absolute;
  right: -35px;
  top: 5px;

  &--file {
    top: 13px;
  }

  &__button {
    display: flex;
    border-width: 0;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &__icon {
    font-size: 20px;
    align-self: center;
    color: $darkergrey;
    background: $lightergrey;

    &--active {
      color: $slategrey;
    }
  }

  &__content {
    z-index: 1051;
    background: $slategrey;
    box-shadow: $centered-box-shadow;
    border-radius: $border-radius;
    padding: 15px;
    color: $white;
    position: relative;
    animation-duration: 0.25s;
    margin-top: -8px;
    margin-left: 5px;

    &--right {
      animation-name: animate-in-right;

      &:after {
        left: -15px;
        top: calc(50% - 10px);
      }
    }

    &--left {
      margin-left: -5px;
      animation-name: animate-in-left;

      &:after {
        transform: rotate(180deg);
        right: -15px;
        top: calc(50% - 10px);
      }
    }

    &--top {
      margin-top: 0;
      animation-name: animate-in-top;

      &:after {
        transform: rotate(-90deg);
        right: calc(50% - 8px);
        bottom: -15px;
      }
    }

    &--bottom {
      margin-top: 0;
      animation-name: animate-in-bottom;

      &:after {
        transform: rotate(90deg);
        right: calc(50% - 8px);
        top: -15px;
      }
    }

    &:after {
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-right-color: $slategrey;
      border-width: 10px;
    }
  }

  &__close-icon {
    position: absolute;
    top: 12px;
    left: 12px;
    opacity: 0.6;
    font-size: 16px;
    cursor: pointer;
    display: none;
  }

  &__body {
    max-width: 300px;
    word-break: break-word;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}
