.dashboard {
  &__image-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    > img {
      margin-right: 10px;
    }
    > div {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .detail-view-j {
    &-check {
      border-bottom: 1px solid #F0F0F0;
      display: flex;
      padding: 10px 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
    }

    &-review {
      border-bottom: 1px solid #F0F0F0;
      display: flex;
      font-weight: bold;
      padding: 10px 0;
      flex-direction: row;
      align-items: center;
      width: 100%;

      svg {
        margin-right: 10px;
      }
    }

    .description {
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.2px;
    }
  }
  &__filter-by-stage {
    margin: 20px 0 20px 10px;
    width: 100%;
    max-width: 300px;
    font-size: 12px;
    box-shadow: 0 1px 4px rgba(208, 208, 208, 0.25);
    >div {
      border-radius: 0;
      border: none;
      min-height: 36px;
    }
  }
  &__assigned-to-me {
    display: flex;
    align-items: center;
    margin-left: 10px;
    label {
      display: flex;
      align-items: center;
    }
    input[type="checkbox"] {
      margin-right: 7px;
    }
  }
}
