@import 'design/themes';

* {
  box-sizing: border-box;
  outline: none;
  font-variant-ligatures: none;
}

html {
  font-size: 10px;
}

html,
body,
#root {
  height: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: #fbfbfb;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: overlay;
}
