@import '../design/themes';
@import '../design/variables';

.portal {
  @include themed() {
    background-color: #fbfbfb;
    color: t($text);
    display: flex;
    flex-direction: row;
    text-align: left;
    width: 100%;
    height: 100%;

    .portal-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;

      .portal-header {
        background: t($bg);
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        border-bottom: 1px solid t($border);

        .hello {
          align-items: center;
          display: flex;
          flex-direction: row;
          font-size: 12px;
          justify-content: flex-end;
          padding: 20px 20px 0 0;
        }

        .view-title {
          font-size: 18px;
          font-weight: bold;
          margin: 0 50px;
          padding-left: 0;
          padding-bottom: 14px;

          background: $tequila-sunrise;
          -webkit-background-clip: text;
          //-webkit-text-fill-color: transparent;
        }
      }

      .workspace {
        flex: 1;
        overflow: scroll;
        padding: 20px 50px;
        background: #eeeeee;
        position: relative;

        //table {
        //  border-collapse: collapse;
        //  font-size: 12px;
        //  width: 100%;
        //
        //  td, th {
        //    border-bottom: 1px solid t($trim);
        //    padding: 8px;
        //  }
        //
        //  tbody {
        //
        //    tr {
        //      &:nth-child(odd) {
        //        background-color: t($trim);
        //      }
        //
        //      &:hover {
        //        background: t($highlight);
        //        cursor: pointer;
        //      }
        //    }
        //  }
        //}
      }
    }

    .card {
      background: t($bg);

      .card-title {
        border-bottom: 1px solid t($trim);
      }
    }

    .mask {
      .modal {
        background: t($bg);

        &-head {
          border-bottom: 1px solid t($trim);
        }
      }
    }

    .client {
      background: t($bg);

      .nav-tabs {
        a {
          color: t($text);
        }

        .active {
          color: t($text);
        }
      }

      .card {
        .btn-link {
          color: t($text);
        }
      }
    }
  }
}

//.nav {
//  & .nav-tabs {
//    &
//  }
//}

//.active {
//  color: #666666;
//  background-color: transparent;
//  border-color: #dee2e6 #dee2e6 #fff;
//  text-decoration: none;
//}
