.checkbox {
  $root: &;

  user-select: none;
  margin-bottom: 25px;

  &__label-container {
    display: flex;
    flex-direction: row;

    &:hover {
      cursor: pointer;
    }
  }

  &__label {
    flex: 2;
    line-height: 35px;
    transition: $transition;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: right;
    padding-right: 15px;

    &--right {
      text-align: left;
      padding-left: 15px;
    }
  }

  &__label-container{
    display: flex;
    .tooltip {
      position: relative;
      top: 0px;
      right: -5px;
    }
  }

  &__input-container {
    position: relative;
    display: flex;
    height: 35px;
    align-items: center;
  }

  &__input {
    width: 0;
    opacity: 0;
    margin: 0;
  }

  &__input-fake-checkbox {
    position: relative;

    &:before {
      content: '';
      cursor: pointer;
      width: 25px;
      height: 25px;
      border-radius: $border-radius;
      border: 1px solid $grey;
      background: $white;
      display: block;
    }

    &--checked {
      &:before {
        content: '\F12C';
        font: normal normal normal 18px/1 "Material Design Icons";
        text-align: center;
        line-height: 25px;
        color: $darkergrey;
      }
    }

    &--focus {
      &:before {
        border-color: $blue;
        box-shadow: $block-shadow;
      }
    }
  }

  &--disabled {
    pointer-events: none;

    #{$root}__input-fake-checkbox:before {
      background: $lightgrey;
      border-color: $grey;
    }
  }
}
