.wired-transactions {
  label {
    padding-right: 1.6rem;
  }
  &__detail-view {
    .detail-view__value {
      font-weight: normal;
      max-width: 22.4rem;
    }
    &__header {
      font-size: 18px;
      line-height: 23.4px;
      font-weight: 300;
    }
    .detail-view__highlight-value {
      font-size: 18px;
      font-weight: 400;
      color: $green;
      .transactions__custom-tooltip {
        min-width: auto;
        margin-top: 5px;
      }
    }
    .detail-view__highlight-name:nth-child(1) {
      text-align: end;
      margin-right: 1.6rem;
    }
    .input__field--custom-select {
      border: 0px;
      width: 20.8rem;
      margin-right: 3.2rem;
    }
    .input__field--custom-select__control {
      border: 0px !important;
      background-color: transparent;
      margin-right: -2.4rem;
      padding-right: 1.6rem;
    }
    .input__field--invalid {
      background-color: transparent;
    }
    .input__field--custom-select__single-value {
      text-align: right;
      margin-left: 1.6rem;
    }

    .input__field--custom-select--is-disabled {
      margin-right: 0px;
    }

    .input__field--custom-select--is-disabled + .wired-transactions__detail-view__status-icon {
      display: none;
    }

    &__status-icon {
      margin-top: 4px;
    }
  }
}
