.checkbox-group {
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  &__item {
    flex: 1;
  }

}
