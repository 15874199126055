.toast-notification {
  background: $white;
  padding: 0 25px 0 0;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  &--success {
    background: scale-color($green, $lightness: 90%);
  }

  &--warning {
    background:  scale-color($orange, $lightness: 90%);
  }

  &--error {
    background:  scale-color($red, $lightness: 90%);
  }

  &--info {
    background:  scale-color($blue, $lightness: 90%);
  }

  &__body {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__content {
    width: calc(100% - 48px);
    margin: 10px 0;
  }

  &__icon {
    font-size: 18px;
    padding: 0 15px;
    align-self: flex-start;
    margin-top: 17px;

    &--success {
      color: $green;
    }

    &--warning {
      color: $orange;
    }

    &--error {
      color: $red;
    }

    &--info {
      color: $blue;
    }
  }

  &__close-icon {
    color: $darkergrey;
    font-size: 15px;
    position: absolute;
    top: 10px;
    right: 15px;
  }

  &__title {
    color: $darkergrey;
    text-transform: capitalize;
    font-weight: 600;
    margin-top: 10px;
  }

  &__text {
    color: $darkergrey;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 10px;
  }

  &__list {
    margin: 10px 0;
    padding: 0 15px;
    color: $darkergrey;
  }

  &__progress {
    height: 3px;

    &--success {
      background-color: $green;
    }

    &--warning {
      background-color: $orange;
    }

    &--error {
      background-color: $red;
    }

    &--info {
      background-color: $blue;
    }
  }
}