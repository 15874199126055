.colour-indicator {
  display: inline-flex;
  margin: 0px 0 0 10px;
  align-items: center;
  justify-content: center;

  &--label-within {
    flex-direction: row;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: $bolder;
    color: $white;
  }

  &__bulb {
    border-radius: 50%;
    margin-right: 5px;
    min-width: 8px;
    min-height: 8px;
    @include colorIndicators;

    &--label-within {
      margin-right: 0;
      border-radius: 20px;
      height: 15px;
      padding: 2px 5px;
    }
  }
}
