@import '../design/variables';

.login {
  background: $sunset;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: scroll;

  &-content {
    width: 600px;
    min-height: calc(100vh - 300px);
    margin: auto;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &-logo {
    width: 60%;
    margin: auto;
  }

  &-footer {
    width: 80%;
    height: 100px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;
    line-height: 100px;
    margin: auto;
  }


  h1 {
    color: white;
    font-size: 24px;
    line-height: 33px;
    margin: 0;
    animation: fadein 5s;
  }

  input {
    font-size: 18px;
    margin-top: 50px;
    transition: 0.5s ease-in-out;

    &[type=text], &[type=password] {
      background: none;
      border: none;
      border-bottom: 1px solid $coconut;
      box-sizing: border-box;
      color: $coconut;
      padding: 15px;
      width: 80%;

      &::placeholder {
        color: $coconut;
      }

      &:focus {
        background: white;
        border: 1px solid $cloud;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: black;

        &::placeholder {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    &[type=submit] {
      background: none;
      border: none;
      box-shadow: none;
      color: $gold;
      width: auto;

      &:hover, &:active {
        color: $amber;
        cursor: pointer;
      }
    }
  }

}

/* Mobile breakpoint */
$breakpoint-mobile: 600px;
@media (max-width: $breakpoint-mobile) {
  h1 {
    font-size: 18px;
  }

  .login-content {
    width: 400px;
  }

  input {
    font-size: 14px;
  }

  .login-footer {
    font-size: 14px;
  }
}


