.pdf {
  .page {
    background: white;
    display: flex;
    padding: 50px;
    flex-direction: column;
    position: relative;
  }

  .cover {
    .preview {
      font-size: 10px;
    }

    .header {
      margin: 20px 0;
      text-align: center;

      .logo {
        height: 40px;
      }
    }

    .content {
      min-height: 350px;
    }

    .footer {
      font-size: 10px;
      justify-content: flex-end;
      margin: 20px;
    }

    .watermark {
      top: 50%;
      left: 50%;
      bottom: 0;
      position: absolute;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      z-index: 2000,
    }
  }

  .section {
    background-color: blue;
    margin: 10px;
    padding: 10px;
    flex-grow: 1;
  }
}
