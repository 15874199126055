@import 'design/themes';

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  min-width: 600px;
  margin: 10px 20px 0 0;

  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    background-color: #FFB100;
    color: #333333;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    padding: 10px 20px;
    transition: background-color 0.6s ease;
    box-shadow: 0 4px 10px rgba(217, 217, 217, 0.25);

    /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
    &:hover {
      background-color: #007AD2;

      .active {
        background-color: white;
      }
    }
  }
}

/* Style the accordion content title */
.accordion__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  padding: 0 0 0 10px;
  border: none;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(-90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: auto;
  transition: max-height 0.4s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 20px 50px;
  line-height: 170%;

  a {
    color: #007AD2;

    &:hover, &:active {
      color: #cc6d26;
    }
  }
}
