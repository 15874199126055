.edd-tabs {
  height: calc(100% - 100px);
  .input--textarea {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #E9E9E9;
    border-radius: 4px;
    box-sizing: border-box;
    word-wrap: break-word;
    font-family: "Open Sans", sans-serif;
    height: auto;
  }
}
