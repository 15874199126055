.transactions {
  display: flex;
  flex-direction: column;
  flex: 1;
  label {
    padding-right: 1.6rem;
  }

  &__content-container {
    padding: 0;
  }

  &__content {
    display: flex;
    padding: 20px 0;
    flex-direction: row;
    height: 100%;
  }

  &__tabs {
    margin-top: -12px;
  }

  &__table {
    width: 100%;
  }

  &__date {
    min-width: 235px;
    width: 225px;
  }

  &__reference {
    min-width: 200px;
  }

  &__account-select {
    border-radius: $border-radius;
    box-shadow: $block-shadow;
    height: 40px;
    background: white;
    margin-bottom: -5px;
    margin-top: 10px;
  }

  &__account-select-input {
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    height: 40px;
    margin-bottom: 0;
    padding: 10px 20px 10px 15px;
    &:focus {
      box-shadow: none;
    }
  }

  &__account-select-icon {
    top: 3px;
  }

  &__dropdown-item {
    color: $darkestgrey;
    line-height: 25px;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    min-width: 24rem;
    &__name {
      display: flex;
      align-items: center;
    }
    &__icon {
      width: 1.6rem;
      height: 16px;
    }
    &__amount {
      min-width: 9.6rem;
      align-self: flex-end;
      display: flex;
      justify-content: flex-start;

      &__currency {
        padding-right: 10px;
        color: $darkestgrey;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 25px;
      }
      &__value {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 25px;
      }
    }

  }

  &__custom-tooltip {
    display: flex;
    min-width: 70px;
    min-height: 17px;
    .tooltip {
      right: 0px;
      left: 5px;
      top: 0px;
      position: relative;
    }

    .tooltip__button {
      height: 15px;
    }
    .tooltip__icon--active, .tooltip__icon {
      background-color: transparent;
      color: inherit;
    }
  }

  &__tooltip-amount{
    display: flex;
    min-height: 17px;
    min-width: 58px;

    .tooltip {
      right: 0px;
      left: 5px;
      top: 0px;
      position: relative;
    }

    .tooltip__button {
      height: 15px;
    }
    .tooltip__icon--active, .tooltip__icon {
      background-color: transparent;
      color: inherit;
    }
  }

  &__orange-background-color{
      background: #ffbb87;
    .transactions__status-color--orange {
      color: #B15925;
    }
  }

  &__status-color {
    @include colorTextIndicators;
  }
}
