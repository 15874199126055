// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff
$line-height-base: 1.5 !default;
$border-width: 1px !default;
$border-radius-lg: 0.5rem !default;
$border-color: $grey !default;
$enable-rounded: true !default;
$zindex-modal: 1050 !default;
$zindex-modal-backdrop: 1040 !default;
$modal-dialog-margin: 0.8rem !default;
$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-shadows: false !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

$modal-inner-padding: 1.6rem !default;

$modal-dialog-margin: 0.8rem !default;
$modal-dialog-margin-y-sm-up: 2.8rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-color: null !default;
$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-box-shadow-xs: 0 0.4rem 0.8rem rgba($black, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.8rem 1.6rem rgba($black, 0.5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: $border-color !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y: 1.6rem !default;
$modal-header-padding-x: 1.6rem !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 100% !default;

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin border-radius($radius: $border-radius, $fallback-border-radius: false) {
  @if $enable-rounded {
    border-radius: $radius;
  } @else if $fallback-border-radius != false {
    border-radius: $fallback-border-radius;
  }
}

@mixin border-top-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
}

@mixin border-right-radius($radius) {
  @if $enable-rounded {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}

@mixin border-bottom-radius($radius) {
  @if $enable-rounded {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

@mixin border-left-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

@mixin border-top-left-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
  }
}

@mixin border-top-right-radius($radius) {
  @if $enable-rounded {
    border-top-right-radius: $radius;
  }
}

@mixin border-bottom-right-radius($radius) {
  @if $enable-rounded {
    border-bottom-right-radius: $radius;
  }
}

@mixin border-bottom-left-radius($radius) {
  @if $enable-rounded {
    border-bottom-left-radius: $radius;
  }
}

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    $result: ();

    @if (length($shadow) == 1) {
      // We can pass `@include box-shadow(none);`
      $result: $shadow;
    } @else {
      // Filter to avoid invalid properties for example `box-shadow: none, 1px 1px black;`
      @for $i from 1 through length($shadow) {
        @if nth($shadow, $i) != 'none' {
          $result: append($result, nth($shadow, $i), 'comma');
        }
      }
    }
    @if (length($result) > 0) {
      box-shadow: $result;
    }
  }
}

// stylelint-disable property-blacklist
@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }

  @if $enable-prefers-reduced-motion-media-query {
    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }
}

.modal-open {
  // Kill the scroll on the body
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695

  opacity: 0;

  &.show {
    opacity: 1;
  }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: $modal-dialog-margin;
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;

  // When fading in the modal, animate it to slide down
  .modal.fade & {
    @include transition($modal-transition);
    transform: $modal-fade-transform;
  }

  .modal.show & {
    transform: $modal-show-transform;
  }
}

.modal-dialog-scrollable {
  display: flex; // IE10/11
  max-height: calc(100% - #{$modal-dialog-margin * 2});

  .modal-content {
    max-height: calc(100vh - #{$modal-dialog-margin * 2}); // IE10/11
    overflow: hidden;
  }

  .modal-header,
  .modal-footer {
    flex-shrink: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - #{$modal-dialog-margin * 2});

  // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
  &::before {
    display: block; // IE10
    height: calc(100vh - #{$modal-dialog-margin * 2});
    content: '';
  }

  // Ensure `.modal-body` shows scrollbar (IE10/11)
  &.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .modal-content {
      max-height: none;
    }

    &::before {
      content: none;
    }
  }
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  // counteract the pointer-events: none; in the .modal-dialog
  color: $modal-content-color;
  pointer-events: auto;
  background-color: $modal-content-bg;
  background-clip: padding-box;
  border: $modal-content-border-width solid $modal-content-border-color;
  @include border-radius($modal-content-border-radius);
  @include box-shadow($modal-content-box-shadow-xs);
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  width: 100vw;
  height: 100vh;
  background-color: $modal-backdrop-bg;

  // Fade for backdrop
  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: $modal-backdrop-opacity;
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  display: flex;
  align-items: flex-start; // so the close btn always stays on the upper right corner
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  padding: $modal-header-padding;
  border-bottom: $modal-header-border-width solid $modal-header-border-color;
  @include border-top-radius($modal-content-border-radius);

  .close {
    padding: $modal-header-padding;
    // auto on the left force icon to the right even when there is no .modal-title
    margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
  }
}

// Title text within header
.modal-title {
  font-size: 2rem;
  margin: 0;
  line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when should there be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: $modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
  display: flex;
  align-items: center; // vertically center
  justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: $modal-inner-padding;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;
  @include border-bottom-radius($modal-content-border-radius);

  // Easily place margin between footer elements
  > :not(:first-child) {
    margin-left: 0.4rem;
  }

  > :not(:last-child) {
    margin-right: 0.4rem;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@media (min-width: 576px) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    max-width: $modal-md;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .modal-lg,
  .modal-xl {
    max-width: 90%;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - #{$modal-dialog-margin-y-sm-up * 2});

    .modal-content {
      max-height: calc(100vh - #{$modal-dialog-margin-y-sm-up * 2});
    }
  }

  .modal-dialog-centered {
    min-height: calc(100% - #{$modal-dialog-margin-y-sm-up * 2});

    &::before {
      height: calc(100vh - #{$modal-dialog-margin-y-sm-up * 2});
    }
  }

  .modal-content {
    @include box-shadow($modal-content-box-shadow-sm-up);
  }

  .modal-sm {
    max-width: $modal-sm;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: $modal-lg;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: $modal-xl;
  }
}

button.close {
  padding: 0;
  background-color: initial;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 2.4rem;

  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  &__content {
    background: $lightestgrey;
  }

  &__header {
    height: 65px;
    border-bottom: 1px solid $lightergrey;
    position: relative;
    align-items: center;
    padding: 0;
    padding: 0 30px;
  }

  &__title {
    font-size: 14px;
    color: $darkergrey;
    font-weight: 600;
  }

  &__close-icon {
    font-size: 16px;
    color: $darkergrey;
    cursor: pointer;
  }

  &__body {
    padding: 25px 30px;
    color: $darkergrey;
  }

  &__footer {
    padding: 0 30px;
    border-top: 1px solid $lightergrey;
  }

  &__form {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.modal-overflow-visible {
  .modal-content {
    overflow: visible;

    .modal__body {
      overflow: visible;
    }
  }
}
