/* BOOTSTRAP OVERRIDES */

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  &:hover,
  &:focus,
  &:active {
    background-color: $primary-color-hover;
    border-color: $primary-color-hover;
  }
}

.btn-secondary {
  background-color: $secondary-color;
  border-color: $secondary-color;
  &:hover,
  &:focus,
  &:active {
    background-color: $secondary-color-hover;
    border-color: $secondary-color-hover;
  }
}

/* CARD */

.card-title {
  font-size: 2.4rem;
  font-weight: normal;
}

.card-subtitle {
  font-size: 2rem;
  font-weight: normal;
}

/* TABLES */

.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .card-title {
    margin-bottom: 0;
  }
}
.table-responsive {
  max-height: 86vh;
}
.table {
  a:not(.btn) {
    color: #0266d2;
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
      color: #113454;
    }
  }
  &.table-sm {
    td {
      line-height: 1.8;
    }
  }
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
  height: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #000000;
}