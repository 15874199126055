/* CSS Transition */
.alert-enter {
  opacity: 0;
  -moz-transform: scale(0.9) translate(0px, 100vh);
  -webkit-transform: scale(0.9) translate(0px, 100vh);
  -o-transform: scale(0.9) translate(0px, 100vh);
  -ms-transform: scale(0.9) translate(0px, 100vh);
  transform: scale(0.9) translate(0px, 100vh);
}

.alert-enter-active {
  opacity: 1;
  -moz-transform: scale(1) translate(0px, -100vh);
  -webkit-transform: scale(1) translate(0px, -100vh);
  -o-transform: scale(1) translate(0px, -100vh);
  -ms-transform: scale(1) translate(0px, -100vh);
  transform: scale(1) translate(0px, -100vh);
  transition: opacity 1000ms, transform 1000ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 1;
  -moz-transform: scale(0.9) translate(0px, -500px);
  -webkit-transform: scale(0.9) translate(0px, -500px);
  -o-transform: scale(0.9) translate(0px, -500px);
  -ms-transform: scale(0.9) translate(0px, -500px);
  transform: scale(0.9) translate(0px, -500px);
  transition: opacity 1000ms, transform 1000ms;
}

.slide-enter {
  -moz-transform: translate(100%, 0);
  -webkit-transform: translate(100%, 0);
  -o-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.slide-enter-active {
  -moz-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: transform ease-in-out 1200ms;
}

.slide-exit {
  -moz-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: transform ease-in-out 1200ms;
}

.slide-exit-active {
  -moz-transform: translate(100%, 0);
  -webkit-transform: translate(100%, 0);
  -o-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

/* Logo animation */
.vector {
  &-logo_white {
    animation: rotate 5s;
  }

  &-dot {
    animation: fadein 5s;
  }

  &-lightOrange {
    animation: fadeRight 5s;
  }

  &-darkOrange {
    animation: fadeDown 5s;
  }

  &-darkBlue {
    animation: fadeUp 5s;
  }

  &-lightBlue {
    animation: fadeLeft 5s;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  67% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translate(-100px, 0px);
  }

  33% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translate(0px, -100px);
  }

  33% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translate(0px, 100px);
  }

  33% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translate(100px, 0px);
  }

  33% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes rotate {
  0% {
    opacity: 0;
  }

  10% {
    transform: rotate(270deg);
  }

  66% {
    opacity: 1;
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideLeft {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}
