$breakpoint-mobile: 600px;

@media (max-width: 1000px) {
  .dashboard {
    //display: grid;
    //grid-gap: 20px;
    //grid-template-areas:
    //"area area"
    //"pie bar"
    //"reports reports";
  }
}

@media (max-width: $breakpoint-mobile) {
  .dashboard {
    display: grid;
    grid-gap: 20px;
    grid-template-areas:
    "area area"
    "pie bar"
    "reports reports";
  }
}