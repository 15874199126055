.not-found {
  background: $tequila-sunrise;
  text-align: center;

  .content {
    width: 600px;
    min-height: calc(100vh - 300px);
    margin: auto;
    padding-top: 100px;
    padding-bottom: 100px;

    .logo {
      width: 60%;
      margin: auto;
    }
  }

  .footer {
    width: 80%;
    height: 100px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;
    line-height: 100px;
    margin: auto;
  }

  a {
    color: $gold;
    font-size: 18px;
    text-decoration: none;

    &:hover, &:active {
      color: $amber;
      cursor: pointer;
    }
  }
}

/* Mobile breakpoint */
$breakpoint-mobile: 600px;
@media (max-width: $breakpoint-mobile) {
  .content {
    width: 400px;
  }

  .footer {
    font-size: 14px;
  }
}
