@font-face {
    font-family: 'Brandon';
    src: url("../../fonts/Brandon_reg.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url("../../fonts/OpenSans-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/OpenSans-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
