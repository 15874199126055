.input {
  display: flex;
  flex-direction: row;
  transition: $transition;
  position: relative;


  // Overriding these dang third party stylesheets with importants...
  &--valid {
    border-color: #28a745 !important;
  }

  &--invalid {
    border-color: #dc3545 !important;
  }

  &--file {
    position: relative;
    display: flex;
    height: 50px;
    width: 500px;
    background: $white;
    box-shadow: $block-shadow;
    border-radius: $border-radius;
    margin-bottom: 10px;
  }

  &--is-invalid {
    background-color: $color-error;
  }

  &--file-with-tooltip {
    width: calc(100% - 40px);
  }

  &--disabled {
    background: $lightgrey;
    border-color: $grey;
  }

  &--select {
    position: relative;
  }

  &--custom-select {
    position: relative;
  }

  &--full-width {
    display: block;
  }

  // Now only for bootstrap input - to be changed
  &--percentage {
    flex-direction: row;
    margin-bottom: 36px;

    &:after {
      content: '%';
      width: 40px;
      background: $lightgrey;
      border: 1px solid $grey;
      margin-left: -2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 $border-radius $border-radius 0;
    }
  }

  &__spinner {
    width: 70px;
    height: 27px;
    margin: auto;
    zoom: 30%;
    right: 30px;
    top: 42px;
  }

  &__label {
    flex: 2 1 0;
    margin-bottom: 8px;
    height: 35px;
    line-height: 35px;
    text-align: right;
    padding-right: 35px;
    transition: $transition;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &--full-width {
      width: 100%;
    }

    &--file {
      flex: none;
      height: 100%;
      padding: 0 25px;
      margin-bottom: 0;
      text-align: left;
      line-height: 50px;
      box-sizing: border-box;
    }

    &--file-required {
      &:after {
        content: '*';
        color: $red;
        padding-left: 5px;
        position: absolute;
      }
    }

    &--required {
      &:after {
        content: '*';
        color: $red;
        padding-left: 5px;
        position: absolute;
        right: 20px;
      }
    }
  }

  &__container {
    position: relative;
    display: flex;
    flex: 4 1 0;

    &--full-width {
      width: 100%;
    }
  }

  &__field {
    flex: 4 1 0;
    height: 35px;
    padding: 6px 10px;
    border-radius: $border-radius;
    border: 1px solid $grey;
    color: $darkergrey;
    transition: $transition;
    font-size: 14px;

    &--file {
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    &--select {
      width: 100%;
      appearance: none;
      -ms-appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      //turns off the webkit expand in IE
      &::-ms-expand {
        display: none;
      }
    }

    &--custom-select {
      flex: 4 1;
      height: 35px;
      padding: 0;
      border-radius: 0;
      border: none;
      color: $darkergrey;
      transition: all 0.2s ease;
      font-size: 14px;

      &__option--is-disabled .transactions__dropdown-item__name {
        color: $grey;
      }
      &__placeholder {
        color: $darkergrey !important; /* styling external component, this is needed, as much as I hate it */
      }
      &__single-value {
        width: calc(100% - 3.2rem) !important; /* styling external component, this is needed, as much as I hate it */
      }
      &__indicators {
        display: none !important; /* styling external component, this is needed, as much as I hate it */
      }
      &__control {
        border-radius: 5px !important; /* styling external component, this is needed, as much as I hate it */
        border: 1px solid $grey !important; /* styling external component, this is needed, as much as I hate it */
        color: $darkergrey !important; /* styling external component, this is needed, as much as I hate it */
        font-size: 12px !important; /* styling external component, this is needed, as much as I hate it */
      }
      &__control--is-focused {
        outline: none !important; /* styling external component, this is needed, as much as I hate it */
        box-shadow: $block-shadow !important; /* styling external component, this is needed, as much as I hate it */
        border: 1px solid $blue !important; /* styling external component, this is needed, as much as I hate it */
      }

      &__menu {
        color: black;
      }
      width: 100%;
      appearance: none;
      -ms-appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      //turns off the webkit expand in IE
      &::-ms-expand {
        display: none;
      }
    }

    &--phone {
      min-width: 100%;
    }

    &--invalid {
      border-color: #dc3545;
      background: #FDEFEF;
      color: #9F3A38;
    }

    &--disabled {
      background: $lightgrey;
      border-color: $grey;
    }

    &--loading {
      background: $lightergrey;
      border-color: $grey;
      pointer-events: none;
    }

    &--with-addon {
      margin-right: 40px;
      border-radius: $border-radius 0 0 $border-radius;
      border-right: none;
    }

    &:focus {
      outline: none;
      box-shadow: $block-shadow;
      border-color: $blue;
    }
  }

  &__max-length {
    position: absolute;
    bottom: 15px;
    right: 0;
    font-size: 11px;
    color: $darkergrey;
  }

  &__addon {
    position: absolute;
    right: 0;
    width: 40px;
    height: 35px;
    border-radius: 0 $border-radius $border-radius 0;
    background: $white;
    border: 1px solid $grey;
    color: $darkgrey;
    text-align: center;
    line-height: 34px;
  }

  &__selector {
    position: absolute;
    right: 10px;
    top: 0;
    color: $darkgrey;
    pointer-events: none;
    font-size: 24px;
    line-height: 35px;

    &--disabled {
      display: none;
    }
  }

  &__fake-wrapper {
    display: flex;
    height: 50px;
    flex-grow: 1;
    position: relative;
    width: 100%;
  }

  &__button {
    width: 180px;
    margin-right: 20px;
  }

  &__file-label {
    color: $blue;
    line-height: 50px;
    text-align: right;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    padding-right: 25px;

    &:hover {
      color: darken($blue, 10%);
    }

    &--required {

      &:after {
        position: absolute;
        margin-left: 3px;
        content: '*';
      }
    }
  }
  &__group-add-button {
    display: block;
    color: #007AD2;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    border: none;
    padding: 0;
    background: transparent;
    :focus {
      outline: none;
    }
  }

  &--textarea {
    flex: 4 1 0;
    height: auto;
    padding: 6px 10px;
    border-radius: $border-radius;
    border: 1px solid $grey;
    color: $darkergrey;
    transition: $transition;
    font-size: 14px;
  }

  &--read-only {
    background-color: transparent;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
}
