@import 'variables';

$bg: 'bg';
$trim: 'trim';
$text: 'text';
$text-secondary: 'text-secondary';
$link: 'link';
$hover: 'hover';
$logo: 'logo';
$border: 'border';
$switch: 'switch';
$sidebar: 'sidebar';
$highlight: 'highlight';

$themes: (
        light: (
                logo: url('../../../img/logos/logo_primary.svg'),
                bg: $coconut,
                trim: #F0F0F0,
                text: $charcoal,
                text-secondary: $coconut,
                link: $gold,
                hover: $coconut,
                border: $azure,
                switch: $blue-hawaiian,
                sidebar: '#active--theme-light',
                highlight: #E9E9E9,
        ),
        dark: (
                logo: url('../../../img/logos/logo_secondary.svg'),
                bg: $charcoal,
                trim: #666666,
                text: $coconut,
                text-secondary: $iron,
                link: $iron,
                hover: $iron,
                border: $gold,
                switch: $tequila-sunrise,
                sidebar: '#active--theme-dark',
                highlight: #777777,
        ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    &.theme--#{$theme} {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
