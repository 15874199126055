.searchbar {
  $el: &;
  margin: 0 20px;
  display: flex;
  justify-content: flex-end;

  &__form-container {
    border-radius: $border-radius;
    background: $white;
    width: 100%;
  }

  &__input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  &__button {
    border-radius: 0 $border-radius $border-radius 0;
    padding-left: 10px;
    padding-right: 10px;
    min-width: auto;
  }

  &__clear {
    padding: 10px;
    color: $red;
    cursor: pointer;
  }

  .tooltip {
    position: relative;
    right: 0;
    top: 0;
    margin: 0 15px 0 15px;
  }

  // Being naughty here, because of bootstrap
  input {
    &#{$el}__input {
      height: 40px;
      border-radius: $border-radius;
      padding: 0 45px 0 10px;
      margin-bottom: 0;
      border: none;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
