.grid-column {
  width: 100%;
  max-width: 1080px;
  margin: auto;
  padding: 0 50px;
}

.cursor {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.scroll {
  overflow: scroll;
}

.no-scroll {
  overflow: hidden;
}

.flex-row {
  display: flex;
  flex-direction: row;

  .f-0 {
    flex: 0 0;
    width: 0;
    visibility: hidden;
  }

  .f-1 {
    flex: 1 1;
  }

  .right {
    margin-left: 20px;
  }
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.ease {
  transition: all ease-in-out 1.5s;
}

.fit {
  min-height: 100%;
  height: fit-content;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mr-1 {
  margin-right: 10px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 10px;
}

.ml-1 {
  margin-left: 10px;
}

@for $i from 1 through 10 {
  $size: 100px * $i;
  .min-width-#{$i} {
    min-width: $size;
  }

  .max-width-#{$i} {
    max-width: $size;
  }

  .min-height-#{$i} {
    min-height: $size;
  }

  .max-height-#{$i} {
    max-height: $size;
  }
}
