.topbar {
  height: $sidebar-logo-container-height;
  display: flex;
  flex-direction: row;
  border: 1px solid $lightergrey;
  background: $white;

  &__chevron {
    position: absolute;
    right: 45px;
    top: 50%;
    transform: translate(0, -50%);
    fill: $darkgrey;
    width: 8px;
    height: 8px;
  }

  &__inner {
    width: 14px;
    height: 14px;
    fill: $blue;
    margin-right: 5px;
  }

  &__topbar-menu {
    right: 0;
    padding-right: 58px;
    position: absolute;
    font-family: 'Open Sans', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    display: flex;
    letter-spacing: 0.2px;
    color: $darkgrey;
    height: $sidebar-logo-container-height;
    cursor: pointer;

    &__dropdown {
      display: flex;
      width: $top-bar-menu-width;
      align-items: center;

      &__avatar {
        background-color: $primary-color-yellow;
        border-radius: 50%;
        height: $dropdown-avatar-size;
        text-align: center;
        width: $dropdown-avatar-size;
        min-width: $dropdown-avatar-size;
        margin-right: 1.6rem;
        .initials {
          font-size: $dropdown-avatar-size / 2; /* 50% of parent */
          line-height: 1;
          position: relative;
          top: $dropdown-avatar-size / 4; /* 25% of parent */
          color: $white;
        }
      }

      &:before {
        border-right: 1px solid $lightergrey;
        content: '';
        margin-right: 20px;
        height: 3.2rem;
        display: inline-block;
      }

      & > span {
        width: $top-bar-menu-width;
      }

      &-content {
        display: none;
        position: absolute;
        top: $sidebar-logo-container-height - 2px;
        right: 0px;
        width: $top-bar-menu-width + 58px;
        color: $blue;
        vertical-align: center;
        border-left: 1px solid $lightergrey;
        border-bottom: 1px solid $lightergrey;
        background-color: $white;
        z-index: 1;

        & > p {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0;
          height: 3.2rem;
          padding-left: 20px;
          padding-top: 0 !important;
          cursor: pointer;

          &:hover {
            background-color: $lightergrey;
            transition: all 0.2s ease;
          }

          & > span {
            padding-top: 0 !important;
          }
        }

        & > p > i {
          margin-right: 12px;
          font-size: 1.6rem;
          vertical-align: center;
        }
      }

      &:hover &-content {
        display: block;
        box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.03);
      }

      &:hover {
        width: $top-bar-menu-width;
        height: $sidebar-logo-container-height;
        box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.03);
        border-left: 1px solid $lightergrey;
        border-bottom: none;
      }

      &:hover:before {
        border: none;
      }

      &__display-name {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        min-width: $top-bar-menu-width - 70;
        max-width: $top-bar-menu-width - 70;
        &__username {
          font-size: 12px;
          color: rgba(34, 34, 34, 0.7);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__company-name {
          font-size: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__bell {
    font: $font-mdi;
    text-align: center;
    line-height: 25px;
    color: $darkergrey;
    border-left: 1px solid $lightergrey;
    width: 60px;
    height: 100%;
    cursor: pointer;

    &__badge {
      position: relative;
      cursor: pointer;
      &:after {
        position: absolute;
        right: 15px;
        top: 12px;
        line-height: 11px;
        padding: 3px;
        color: #fff;
        background-color: #e74c3c;
        font-size: 10px;
        content: attr(data-badge);
        border: solid 1px white;
        min-width: 10px;
        height: 10px;
        border-radius: 50%;
        text-align: center;
      }
    }
  }
}
